import { ApolloProvider } from '@apollo/client'
import {
  createGenerateClassName,
  StylesProvider,
  ThemeProvider,
} from '@material-ui/core'
import { theme, getPlatformClient } from '@NKE/utils'
import { UserInfoProvider } from '@nke/spp-components.context.user-info'
import './i18next'
import React, { Suspense } from 'react'
import Loader from './custom-single-spa-init/loader'

const generateClassName = createGenerateClassName({
  disableGlobal: true,
  seed: 'themePlatformComponent',
})

export default function Root(props) {
  const LazyPlatform = React.lazy(() => import('./pages/platform'))
  return (
    <StylesProvider generateClassName={generateClassName}>
      <ThemeProvider theme={theme}>
        <UserInfoProvider token={localStorage.getItem('token') ?? ''}>
          <ApolloProvider client={getPlatformClient()}>
            <Suspense fallback={<Loader />}>
              <LazyPlatform />
            </Suspense>
          </ApolloProvider>
        </UserInfoProvider>
      </ThemeProvider>
    </StylesProvider>
  )
}
