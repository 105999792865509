import React from 'react'
import CubeLoader from './cube_loader'

const Loader = () => {
  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
      }}
    >
      <div style={{ margin: 'auto' }}>
        <CubeLoader />
      </div>
    </div>
  )
}

export default Loader
