export default {
  en: {
    translation: {
      actions: 'Actions',
      add: 'Add',
      addTagValue: 'Add tag value',
      Admin: 'Admin',
      cancel: 'Cancel',
      color: 'Color',
      custAndRet: 'Customers & Retailer',
      customer: 'Customer',
      Customer: 'Customer',
      customers: 'Customers',
      delete: 'Delete',
      deleted: 'deleted',
      deleteDialog: 'Are you sure to delete',
      edit: 'Edit',
      emailSent: 'Email sent',
      en: 'English',
      errDeleting: 'Error on deleting',
      errEmail: 'Error on sending email',
      errUpdating: 'Error on updating',
      es: 'Spanish',
      firstPage: 'First page',
      fr: 'French',
      increment: 'Increment',
      it: 'Italian',
      lastPage: 'Last page',
      loading: 'Loading..',
      name: 'Name',
      nextPage: 'Next page',
      noRecords: 'No records to display',
      notVerified: 'Not verified',
      number: 'Number',
      Operator: 'Operator',
      preview: 'Preview',
      previousPage: 'Previous page',
      product: 'Product',
      prop: 'Property',
      property: 'Property',
      pt: 'Portuguese',
      region: 'Region',
      Retailer: 'Retailer',
      revisionMode: 'Revision mode',
      rows: 'Rows',
      salesOrder: 'Sales order',
      salesOrders: 'Sales orders',
      save: 'Save',
      search: 'Search',
      sendingEmail: 'Sending inviation email…',
      sendInvitation: 'Send invitation',
      serialNumber: 'Serial number',
      serialNumbers: 'Serial numbers',
      SuperAdmin: 'Super Admin',
      tagValue: 'Tag value',
      type: 'Type',
      updated: 'updated',
      user: 'User',
      User: 'User',
      users: 'Users',
      userType: 'User type',
      value: 'Value',
      values: 'Values',
      verified: 'Verified',
      view: 'View',
    },
  },
  it: {
    translation: {
      actions: 'Azioni',
      add: 'Aggiungi',
      addTagValue: 'Aggiungi valore',
      Admin: 'Amministratore',
      cancel: 'Annulla',
      color: 'Colore',
      custAndRet: 'Clienti & Rivenditori',
      customer: 'Cliente',
      Customer: 'Cliente',
      customers: 'Clienti',
      delete: 'Elimina',
      deleted: 'eliminato',
      deleteDialog: 'Sei sicuro di volere eliminare',
      edit: 'Modifica',
      emailSent: 'Email inviata',
      en: 'Inglese',
      errDeleting: "Errore durante l'eliminazione di",
      errEmail: "Errore durante l'invio dell'email",
      errUpdating: "Errore durante l'aggiornamento di",
      es: 'Spagnolo',
      firstPage: 'Prima pagina',
      fr: 'Francese',
      increment: 'Incremento',
      it: 'Italiano',
      lastPage: 'Ultima pagina',
      loading: 'Caricamento..',
      name: 'Nome',
      nextPage: 'Pagina successiva',
      noRecords: 'Nessun dato da visualizzare',
      notVerified: 'Non verificato',
      number: 'Numero',
      Operator: 'Operatore',
      preview: 'Anteprima',
      previousPage: 'Pagina precedente',
      product: 'Prodotto',
      prop: 'Proprietà',
      property: 'Proprietà',
      pt: 'Portoghese',
      region: 'Regione',
      Retailer: 'Rivenditore',
      revisionMode: 'Modalità di revisione',
      rows: 'Righe',
      salesOrder: 'Ordine di vendita',
      salesOrders: 'Ordini di vendita',
      save: 'Salva',
      search: 'Cerca',
      sendingEmail: "Invio email d'invito…",
      sendInvitation: 'Invia invito',
      serialNumber: 'Numero di serie',
      serialNumbers: 'Numeri di serie',
      SuperAdmin: 'Super Amministratore',
      tagValue: 'Valore tag',
      type: 'Tipo',
      updated: 'aggiornato',
      user: 'Utente',
      User: 'Utente',
      users: 'Utenti',
      userType: 'Tipo utente',
      value: 'Valore',
      values: 'Valori',
      verified: 'Verificato',
      view: 'Visualizza',
    },
  },
  es: {
    translation: {
      cancel: 'Cancelar',
      delete: 'Eliminar',
      edit: 'Editar',
      loading: 'Cargando..',
      name: 'Nombre',
      previousPage: 'Pagina anterior',
      region: 'Región',
      salesOrder: 'Órdenes de venta',
      salesOrders: 'Ordenes de venta',
      search: 'Buscar',
      serialNumber: 'Número de serie',
      serialNumbers: 'Números de serie',
      type: 'Tipo',
      users: 'Usuarios',
      verified: 'Verificado',
    },
  },
}
