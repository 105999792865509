import React from 'react'

import './cube_loader.scss'

const CubeLoader = ({ otherClassName = '' }) => {
  return (
    <div className={`boxes ${otherClassName}`}>
      <div className='box'>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div className='box'>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div className='box'>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div className='box'>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}

export default CubeLoader
